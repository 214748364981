import http from "@/utils/request";

//运营数据
export function getOperatePlanReach(data) {
    return http({
        method: "post",
        url: "/report/operatePlanReach.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}
//销售结构
export function getProductSalesStructure(data) {
    return http({
        method: "post",
        url: "/report/productSalesStructure.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}
//动消率
export function getTurnoverRate(data) {
    return http({
        method: "post",
        url: "/report/turnoverRate.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}
//合并数据
export function getAllInfoList(data) {
    return http({
        method: "get",
        url: "/coreFunction/findStatementOfOperations.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        params:data
    });
}

//合并数据导出
export function exportAllInfoList(data) {
    return http({
        method: "post",
        url: "/report/export.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        responseType: 'blob',
        data
    });
}