import {
  getAllInfoList,
  exportAllInfoList
} from "./api.js";
import Util from "@/utils/utils";
import OperationTable from "./components/OperationTable";

import moment from "moment";
export default {
  components: {
    OperationTable
  },
  data() {
    return {
      expLoading: false,
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/reports/operation",
          name: "myStock",
          title: "运营一张表"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      count: {
        totalOccQty: 0,
        totalActQty: 0,
        totalAvaQty: 0
      },
      columns1: [
        {
          label: "品类",
          prop: "categoryName",
          width: 80
        }
      ],
      columns2: [
        {
          label: "完成",
          prop: "collectionDeliveryAmountComplete",
          width: 110
        },
        {
          label: "任务",
          prop: "collectionDeliveryTask",
          width: 110
        },

        {
          label: "完成率",
          prop: "collectionDeliveryAmountCompletePercent",
          width: 80

        },
        {
          label: "同比",
          prop: "collectionDeliveryAmountYearOverYear",
          width: 80

        }
      ],
      columns3: [
        {
          label: "毛利率",
          prop: "grossMarginGrossMargin",
          width: 100
        },
        {
          label: "金额",
          prop: "grossMarginAmount",
          width: 80
        }
      ],
      columns4: [

        {
          label: "当月完成",
          prop: "distIncreaseMonthComplete",
          width: 120
        },
        {
          label: "年累完成",
          prop: "distIncreaseYearComplete",
          width: 100
        },
        {
          label: "年累目标",
          prop: "distIncreaseYearPlan",
          width: 120
        }
      ],
      columns5: [
        {
          label: "当月完成率",
          prop: "distrateMonthCompletePercente",
          width: 130
        },
        {
          label: "当月动销数量",
          prop: "distrateMonthNum",
          width: 130
        },
        {
          label: "当月动销率目标",
          prop: "distrateMonthPlan",
          width: 130
        }
      ],
      columns6: [
        {
          label: "当月完成",
          prop: "distoutputMonthComplete",
          width: 120
        },

        {
          label: "当月单店目标",
          prop: "distoutputMonthPlan",
          width: 120
        },
        {
          label: "同比增幅",
          prop: "distoutputYearOverYear",
          width: 80
        }
      ],
      columns7: [
        {
          label: "当月完成",
          prop: "salesStructureMonthComplete",
          width: 120
        },
        {
          label: "高中端目标",
          prop: "salesStructureMidHighPlan",
          width: 120
        }
      ],
      columns8: [

        {
          label: "当月完成",
          prop: "dedicatedMachineMonthComplete",
          width: 120
        },
        {
          label: "占比目标",
          prop: "dedicatedMachineProportionPlan",
          width: 100
        }
      ],
      columns9: [
        {
          label: "当月占比",
          prop: "frontChannelMonthProportion",
          width: 120
        },
        {
          label: "占比目标",
          prop: "frontChannelProportionPlan",
          width: 100
        },
        {
          label: "完成率",
          prop: "frontChannelRateComplete",
          width: 100
        }
      ],
      columns10: [
        {
          label: "市场覆盖率",
          prop: "marketCoverageRateMarketCoverageRate",
          width: 120
        },
        {
          label: "已覆盖乡镇总数",
          prop: "marketCoverageRateCoverageTownsNum",
          width: 130
        },
        {
          label: "环比",
          prop: "marketCoverageRatechainRelativeRatio",
          width: 130
        }


      ],
      // 库存周转
      columns11: [
        {
          label: "周转天数",
          prop: "inventoryTurnoverturnoverDays",
          width: 130
        },
        {
          label: "平均库存量",
          prop: "inventoryTurnoverAverageInventory",
          width: 130
        },
        {
          label: "日销",
          prop: "inventoryTurnoverDailySalesNum",
          width: 130
        }
      ],
      // 月跑动
      columns12: [
        {
          label: "跑动率",
          prop: "monthlyRunRatedistributorRunRate",
          width: 80
        },

        {
          label: "已跑动门店",
          prop: "monthlyRunRatedistributorRunNum",
          width: 120
        }
      ],
      columns13: [
        {
          label: "跑动率",
          prop: "monthlyRunRatesinkingRunRate",
          width: 80
        },
        {
          label: "已跑动门店",
          prop: "monthlyRunRatesinkingRunNum",
          width: 120
        }
      ],

      data: [],
      terms: {},
      showLoading: false,
      operateData: {
        completion: 0,
        completionYear: 0,
        salesStructureData: 0,
        turnoverRateData: 0,
        arr: [],
        salesStructureDataResult: {
          month: {}
        },
        turnoverRateDataResult: {
          ranking: {}
        },
        operateDataResult: {
          ranking: {},
          rankingMonth: {}
        }
      },
      currentPreviousDay: ""
    };
  },
  mounted() {
    this.getYesterday();
  },
  methods: {
    getYesterday() {
      let day1 = new Date();
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
      this.currentPreviousDay = day1.getFullYear() + "/" + (day1.getMonth() + 1) + "/" + day1.getDate();
    },
    searchFun(arg) {
      this.terms = arg[0];
      this.pager.pageNo = 1;
      this.getData();
    },
    exportFile() {
      this.expLoading = true;
      let data = {
        month: moment(new Date()).format("YYYYMM"),
        operatorCode: this.$store.state.user.userInfo.customer.cisCode,
        ...this.terms
      };
      exportAllInfoList(data)
        .then(res => {
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "运营商结构.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .catch(err => {
          this.expLoading = false;
        });
    },
    getData() {
      this.showLoading = true;
      let data = {
        month: moment(new Date()).format("YYYYMM"),
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        ...this.terms
      };
      let dataA = {
        queryTime: moment(new Date()).format("YYYYMM"),
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        ...this.terms
      };
      getAllInfoList(dataA).then(res => {
        const operateData = (res.data && res.data.list || []);
        // const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        this.pager.count = operateData.length;
        // this.operateData.arr = operateData.slice(start, start + this.pager.pageSize);
        this.operateData.arr = operateData;
          if(this.operateData.arr.length > 0) {
            this.operateData.arr.forEach(item => {
              //回款提货额
              item.collectionDeliveryAmountComplete =item.collectionDeliveryAmount?item.collectionDeliveryAmount.complete:'';
              item.collectionDeliveryTask = item.collectionDeliveryAmount?item.collectionDeliveryAmount.task:'';
              item.collectionDeliveryAmountCompletePercent =item.collectionDeliveryAmount? item.collectionDeliveryAmount.completePercent:'';
              item.collectionDeliveryAmountYearOverYear = item.collectionDeliveryAmount?item.collectionDeliveryAmount.yearOverYear:'';
              //毛利率
              item.grossMarginGrossMargin = item.grossMargin ? item.grossMargin.grossMargin :'';
              item.grossMarginAmount = item.grossMargin?item.grossMargin.amount: '';
              //分销网络拓展与维护
              //  分销网络净增
              item.distIncreaseMonthComplete = item.distributeNetworkDevMaintenance?item.distributeNetworkDevMaintenance.increaseMonthComplete: '';
              item.distIncreaseYearComplete =  item.distributeNetworkDevMaintenance?item.distributeNetworkDevMaintenance.increaseYearComplete:'';
              item.distIncreaseYearPlan = item.distributeNetworkDevMaintenance?item.distributeNetworkDevMaintenance.increaseYearPlan:'';
              //分销网络动效率
              item.distrateMonthCompletePercente =item.distributeNetworkDevMaintenance? item.distributeNetworkDevMaintenance.rateMonthCompletePercent:'';
              item.distrateMonthNum = item.distributeNetworkDevMaintenance?item.distributeNetworkDevMaintenance.rateMonthNum:'';
              item.distrateMonthPlan =  item.distributeNetworkDevMaintenance?item.distributeNetworkDevMaintenance.rateMonthPlan:'';
              //分销网络单店产出
              item.distoutputMonthComplete =  item.distributeNetworkDevMaintenance?item.distributeNetworkDevMaintenance.outputMonthComplete:'';
              item.distoutputMonthPlan =  item.distributeNetworkDevMaintenance?item.distributeNetworkDevMaintenance.outputMonthPlan:'';
              item.distoutputYearOverYear =  item.distributeNetworkDevMaintenance?item.distributeNetworkDevMaintenance.outputYearOverYear:'';

              //  销售结构(%)
              item.salesStructureMonthComplete = item.salesStructure.monthComplete?item.salesStructure.monthComplete:'';
              item.salesStructureMidHighPlan = item.salesStructure.monthComplete?item.salesStructure.midHighPlan:'';
              //  专供机占比
              item.dedicatedMachineMonthComplete = item.dedicatedMachine?item.dedicatedMachine.monthComplete:'';
              item.dedicatedMachineProportionPlan = item.dedicatedMachine?item.dedicatedMachine.proportionPlan:'';

              //   增量业务(前置渠道)(%)
              item.frontChannelMonthProportion =item.frontChannel? item.frontChannel.monthProportion:'';
              item.frontChannelProportionPlan = item.frontChannel?item.frontChannel.proportionPlan:'';
              item.frontChannelRateComplete = item.frontChannel?item.frontChannel.rateComplete:'';

              //市场覆盖率
              item.marketCoverageRateMarketCoverageRate = item.marketCoverageRate?item.marketCoverageRate.marketCoverageRate:'';
              item.marketCoverageRateCoverageTownsNum = item.marketCoverageRate?item.marketCoverageRate.coverageTownsNum:'';
              item.marketCoverageRatechainRelativeRatio =  item.marketCoverageRate?item.marketCoverageRate.chainRelativeRatio:'';
              //库存周转(天
              item.inventoryTurnoverturnoverDays =item.inventoryTurnover?item.inventoryTurnover.turnoverDays:'';
              item.inventoryTurnoverAverageInventory = item.inventoryTurnover?item.inventoryTurnover.averageInventory:'';
              item.inventoryTurnoverDailySalesNum = item.inventoryTurnover?item.inventoryTurnover.dailySalesNum:'';
              // 跑动率
              item.monthlyRunRatedistributorRunRate = item.monthlyRunRate?item.monthlyRunRate.distributorRunRate:'';
              item.monthlyRunRatedistributorRunNum = item.monthlyRunRate?item.monthlyRunRate.distributorRunNum:'';
              item.monthlyRunRatesinkingRunRate = item.monthlyRunRate?item.monthlyRunRate.sinkingRunRate:'';
              item.monthlyRunRatesinkingRunNum = item.monthlyRunRate?item.monthlyRunRate.sinkingRunNum:'';

            });
          }

        this.showLoading = false;
      });
    }
  }
};